import { FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import cvdApp from '../components/color-blindness-app/editor.controller';
import { checkIsSafari, getFrameUrl } from '.';
import { EditorSDK } from '@wix/editor-platform-sdk-types';
import { ViewportType } from '../types';

const TOKEN = 'token';

export const openSidePanel = async (
  editorSdk: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const isSafari = checkIsSafari();
  const toolsMenuItemOptions = { title: flowAPI.translations.t('app.title') };
  const toolsPanelOptions = {
    title: flowAPI.translations.t('app.settings.title'),
    width: 288,
    height: 149 + 24,
    url: getFrameUrl(cvdApp.type, 'Settings'),
    initialPosition: { x: 100, y: 100 },
  };
  if (isSafari) {
    toolsPanelOptions.width = 500 - 55;
    toolsPanelOptions.height = 202;
  }
  await editorSdk.editor.registerToolsPanel(
    TOKEN,
    toolsMenuItemOptions,
    toolsPanelOptions
  );
};

export const addClassicEventListeners = ({
  editorSDK,
  callbacks,
}: {
  editorSDK: EditorSDK;
  callbacks: any;
}) => {
  editorSDK.addEventListener('switchedToMobileView', async () => {
    await callbacks.updateViewport(ViewportType.MOBILE);
  });
  editorSDK.addEventListener('switchedToDesktopView', async () => {
    await callbacks.updateViewport(ViewportType.DESKTOP);
  });
};
