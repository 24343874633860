
  var editorScriptEntry = require('/home/builduser/work/f2a39511276322d6/packages/color-blindness-addon/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/f2a39511276322d6/packages/color-blindness-addon/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = null;
      const experimentsConfig = null;
      const translationsConfig = {"enabled":true,"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/f2a39511276322d6/packages/color-blindness-addon/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
      const defaultTranslations = {"app.setting.openModal":"Preview","app.settings.title":"Colorblind Simulator","app.settings.description":"See how your site looks for visitors with different types of color blindness.","app.settings.emptyState.safari.title":"Try a different browser","app.settings.emptyState.safari.description":"Safari doesn’t currently support the Colorblind Simulator. Try using a different browser.","app.modal.siteNotPublished.title":"Your site isn’t published yet","app.modal.siteNotPublished.description":"Once you publish, you’ll be able to use the Colorblind Simulator.","app.settings.emptyState.button":"Got It","app.title":"Colorblind Simulator","app.modal.title":"Colorblind Simulator","app.filters.normal.title":"Normal color vision","app.filters.normal.description":"Can see all colors","app.filters.protanopia.title":"Red-blind","app.filters.protanopia.description":"Protanopic (common)","app.filters.deuteranopia.title":"Green-blind","app.filters.deuteranopia.description":"Deuteranopic (uncommon)","app.filters.tritanopia.title":"Blue-blind","app.filters.tritanopia.description":"Tritanopic (rare)","app.filters.achromatopsia.title":"Full color blindness","app.filters.achromatopsia.description":"Achromatopsia (very rare)","app.filters.message":"Make sure your site is accessible for all types of color blindness.","app.pagePicker.label":"Select a page"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'wixlabs-color-blindness',
        appDefId: '8061d276-fae4-4f05-b59f-d77eb2662813',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/f2a39511276322d6/packages/color-blindness-addon/src/components/color-blindness-app/editor.controller.ts');

      const model_0 = null;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "undefined";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"undefined": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "undefined", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
