import { ViewportType } from '@wix/editor-platform-sdk-types';

export const MOBILE_PARAM = '?showMobileView=true';

export const MIN_VP_VAL: Record<ViewportType, number> = {
  DESKTOP: 1001,
  TABLET: 751,
  MOBILE: 320,
};

export const editorXButtons: Array<ViewportType> = [
  'DESKTOP',
  'TABLET',
  'MOBILE',
];
export const editorButtons: Array<ViewportType> = ['DESKTOP', 'MOBILE'];

/// FILTERS
export const dataSVG = `url('data:image/svg+xml,\
<svg xmlns="http://www.w3.org/2000/svg" color-interpolation-filters="linearRGB">\
  <filter id="deuteranomaly">\
    <feColorMatrix values="0.8 0.2 0 0 0 0.2583 0.74167 0 0 0 0 0.14167 0.85833 0 0 0 0 0 1 0" />\
  </filter>\
  <filter id="protanomaly">\
    <feColorMatrix values="0.817  0.183  0.000  0.000  0.000\
                           0.333  0.666  0.000  0.000  0.000\
                           0.000  0.125  0.875  0.000  0.000\
                           0.000  0.000  0.000  1.000 0.000" />\
  </filter>\
  <filter id="tritanomaly">\
    <feColorMatrix values="1.256 -0.077 -0.179  0.000  0.000\
                          -0.078  0.930	 0.148  0.000  0.000\
                           0.005  0.691	 0.304  0.000  0.000\
                           0.000  0.000  0.000  1.000  0.000" />\
  </filter>\
  <filter id="protanopia">\
    <feColorMatrix values="0.567  0.433  0.000  0.000  0.000\
                           0.558  0.442  0.000  0.000  0.000\
                           0.000  0.242  0.758  0.000  0.000\
                           0.000  0.000  0.000  1.000  0.000" />\
  </filter>\
  <filter id="deuteranopia">\
    <feColorMatrix values="0.625  0.375  0.000  0.000  0.000\
                           0.700  0.300  0.000  0.000  0.000\
                           0.000  0.300  0.700  0.000  0.000\
                           0.000  0.000  0.000  1.000  0.000" />\
  </filter>\
  <filter id="tritanopia">\
    <feColorMatrix values="0.95 0.5 0 0 0 0 0.4333 0.5667 0 0 0 0.475 0.525 0 0 0 0 0 1 0" />\
  </filter>\
  <filter id="achromatopsia">\
    <feColorMatrix values="0.213  0.715  0.000  0.072  0.000\
                           0.213  0.715  0.072  0.072  0.000\
                           0.213  0.715  0.072  0.072  0.000\
                           0.000  0.000  0.000  1.000  0.000" />\
  </filter>\
  <filter id="achromatomaly">\
    <feColorMatrix values="0.618  0.320  0.062  0.000  0.000\
                           0.163  0.775  0.062  0.000  0.000\
                           0.163  0.320  0.516  0.000  0.000\
                           0.000  0.000  0.000  1.000  0.000" />\
  </filter>\
</svg>`;

export const getFilterById = (id: string): string => `${dataSVG}#${id}`;

export const filtersArray: Array<string> = [
  'normal',
  'protanopia',
  'deuteranopia',
  'tritanopia',
  'achromatopsia',
];

export enum filterTypes {
  'normal' = 'Normal',
  'protanopia' = 'Protanopia',
  'protanomaly' = 'Protanomaly',
  'deuteranopia' = 'Deuteranopia',
  'deuteranomaly' = 'Deuteranomaly',
  'tritanopia' = 'Tritanopia',
  'tritanomaly' = 'Tritanomaly',
  'achromatopsia' = 'Achromatopsia',
  'achromatomaly' = 'Achromatomaly',
}

// Taken from https://www.ashleysheridan.co.uk/blog/Testing+Colour+Blindness+Effects+Online+with+SVG+Filters
const colorBlindnessTest = `<svg height="0">
<filter id="protanopiaColourMatrix">
    <feColorMatrix type="matrix" values=".56667 .43333 0      0 0
                .55833 .44167 0      0 0
                0      .24167 .75833 0 0
                0      0      0      1 0"></feColorMatrix>
</filter>

<filter id="protanomalyColourMatrix">
    <feColorMatrix type="matrix" values=".81667 .18333 0    0 0
                .33333 .66667 0    0 0
                0      .125   .875 0 0
                0      0      0    1 0"></feColorMatrix>
</filter>

<filter id="deuteranopiaColourMatrix">
    <feColorMatrix type="matrix" values=".625 .375 0  0 0
                .7   .3   0  0 0
                0    .3   .7 0 0
                0    0    0  1 0"></feColorMatrix>
</filter>

<filter id="deutranomalyColourMatrix">
    <feColorMatrix type="matrix" values=".8     .2     0      0 0
                .25833 .74167 0      0 0
                0      .14167 .85833 0 0
                0      0      0      1 0"></feColorMatrix>
</filter>

<filter id="tritanopiaColourMatrix">
    <feColorMatrix type="matrix" values=".95 .5     0      0 0
                0   .43333 .56667 0 0
                0   .475   .525   0 0
                0   0      0      1 0"></feColorMatrix>
</filter>

<filter id="tritanomalyColourMatrix">
    <feColorMatrix type="matrix" values=".96667 .3333  0      0 0
                0      .73333 .26667 0 0
                0      .18333 .81667 0 0
                0      0      0      1 0"></feColorMatrix>
</filter>

<filter id="achromatopsiaColourMatrix">
    <feColorMatrix type="matrix" values=".299 .587 .114 0 0
                .299 .587 .114 0 0
                .299 .587 .114 0 0
                0    0    0    1 0"></feColorMatrix>
</filter>

<filter id="achromatomalyColourMatrix">
    <feColorMatrix type="matrix" values=".618 .32  .62  0 0
                .163 .775 .62  0 0
                .163 .320 .516 0 0
                0    0    0    1 0"></feColorMatrix>
</filter>
</svg>`;
