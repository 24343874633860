import {
  EditorSDK,
  ViewportInfo,
  ViewportType,
} from '@wix/editor-platform-sdk-types';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { MOBILE_PARAM } from '../constants';
import { SiteMapEntry } from '../types';
import cvdApp from '../components/color-blindness-app/editor.controller';
import { FlowAPI } from '@wix/yoshi-flow-editor';

const TOKEN = 'token';
export function getFrameUrl(componentName: string, panelName: string) {
  return getPanelUrl(componentName, panelName, { cdnPort: 3200 });
}

const createUrl = async (editorSDK: EditorSDK) => {
  const siteId = await editorSDK.document.info.getSiteId(TOKEN);
  const publicURL = await editorSDK?.document.info.getPublicUrl(TOKEN);
  const siteMap = await editorSDK?.document.info.getSiteMap(TOKEN);
  const currentPage = await editorSDK?.document.pages.getCurrent(TOKEN);
  const currentPageId = '#' + currentPage?.id;
  // @ts-expect-error
  const pageMap = siteMap.filter(
    (site: SiteMapEntry) => site.type === 'PageLink'
  );
  const { url: previewUrl } = pageMap.find(
    (site: SiteMapEntry) => site.pageId === currentPageId
  );
  const route = previewUrl.split(siteId).at(-1);
  const publicRoute = publicURL + route;

  return publicRoute;
};

export const checkIsSafari = (): boolean =>
  navigator.userAgent.includes('Safari') &&
  !navigator.userAgent.includes('Chrome');

export const appendMobileParams = () => {
  const mobileParams = MOBILE_PARAM;
  window.history.replaceState(
    null,
    'null',
    `${window.location.pathname}${mobileParams}`
  );
};

export const closeModal = (editorSDK: EditorSDK) =>
  editorSDK.editor.canvasOverlay.remove(TOKEN);

export const togglePreviewUrl = ({
  preview,
  changeToMobile,
  isDesktop,
}: {
  preview: string;
  changeToMobile: boolean;
  isDesktop: boolean;
}): string => {
  let previewUrl: string = '';
  if (changeToMobile && isDesktop) {
    previewUrl = preview + MOBILE_PARAM;
  } else if (!changeToMobile && !isDesktop) {
    previewUrl = preview.replace(MOBILE_PARAM, '');
  }

  return previewUrl;
};

export const sdkFactory = (editorSDK: EditorSDK) => {
  const createMobileSuffix = async (isEdX: boolean) => {
    const currentViewport: ViewportType = await getViewport();
    return currentViewport === 'MOBILE' && !isEdX ? MOBILE_PARAM : '';
  };

  const createIframeURL = async () => {
    const url = await createUrl(editorSDK);
    return url;
  };

  const getViewport = async (): Promise<ViewportType> => {
    const currentViewport: ViewportInfo =
      await editorSDK.editor.info.getCurrentViewport();
    const { type } = currentViewport;
    return type;
  };

  return {
    getViewport,
    createIframeURL,
    createMobileSuffix,
  };
};

export const getPanelOnClose = (editorSDK: EditorSDK) => {
  let onClose;
  editorSDK?.panel.onEvent(({ eventType, eventPayload }) => {
    switch (eventType) {
      case 'startConfiguration':
        const { token, initialData } = eventPayload;
        onClose = () => editorSDK.editor.closePanel(token, {});
    }
  });

  return { onClose };
};
