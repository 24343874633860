import { EditorReadyFn, FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorSDK } from '@wix/editor-platform-sdk-types';
import { AppApi } from './types';
import { getFrameUrl, sdkFactory, EditorReady } from './utils';
import cvdApp from './components/color-blindness-app/editor.controller';

let isEditorX = false;
let viewport: any;
const callbacks: any = {};

export const editorReady: EditorReadyFn = async (
  editorSDK: FlowEditorSDK,
  token: string,
  { firstInstall, initialAppData },
  flowAPI: FlowAPI
) => {
  const { getViewport } = sdkFactory(editorSDK);
  isEditorX = flowAPI.environment.isEditorX;
  viewport = await getViewport();
  EditorReady.openSidePanel(editorSDK, flowAPI);

  if (!isEditorX) {
    EditorReady.addClassicEventListeners({ editorSDK, callbacks });
  }
};

// exports is called before editorReady, executed once on editor load
// here you can expose different types of APIs that are exposed to different audiences
export const exports = (editorSdk: FlowEditorSDK, flowAPI: FlowAPI) => ({
  public: createPublicAPI(editorSdk, flowAPI),
  private: createPrivateAPI(editorSdk),
});

// -------------- APP SETUP HELPERS
const createPrivateAPI = (editorSDK: EditorSDK): AppApi => ({
  getIsEditorX: () => isEditorX,
  getViewport: () => viewport,
  registerViewportHandler: (cb: Function) => {
    if (!isEditorX) {
      callbacks.updateViewport = cb;
    }
  },
});

const createPublicAPI = (editorSdk: FlowEditorSDK, flowAPI: FlowAPI) => ({
  openSidePanel: () => EditorReady.openSidePanel(editorSdk, flowAPI),
  // openCanvasOverlay: () => openCanvasOverlay(editorSdk),
  // closeCanvasOverlay: () => closeCanvasOverlay(editorSdk),
});

const openCanvasOverlay = async (editorSdk: FlowEditorSDK) => {
  editorSdk.editor.canvasOverlay.add('TOKEN', {
    url: getFrameUrl(cvdApp.type, 'AppModal'),
  });
};

// const closeCanvasOverlay = async (editorSdk: FlowEditorSDK) => {
//   await editorSdk.editor.canvasOverlay.remove(TOKEN);
// };
