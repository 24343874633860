import { ViewportInfo } from '@wix/editor-platform-sdk-types';
import { filtersArray } from '../constants';

export interface AppApi {
  getIsEditorX: () => boolean;
  getViewport: () => ViewportInfo;
  registerViewportHandler: Function;
}

export enum ViewportType {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
}

export interface SiteMapEntry {
  type: string;
  pageId: string;
  title: string;
  hidden: true;
  isHomePage: true;
  url: string;
}

export type filterType = (typeof filtersArray)[number];
